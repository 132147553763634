import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Granim from 'granim';

import Header from './header';
import BgImage from './BgImage';

import '../styles/fonts.css';
import '../styles/main.scss';

const Layout = ({ children }) => {
  useEffect(() => {
    new Granim({
      element: '#granim-canvas',
      direction: 'diagonal',
      isPausedWhenNotInView: true,
      states: {
        'default-state': {
          gradients: [
            // ['#2cd8d5', '#c5c1ff', '#ffbac3'],
            // ['#2cd8d5', '#c5c1ff'],
            // ['#c5c1ff', '#ffbac3'],
            // ['#5ee7df', '#b490ca'],
            // ['#d9afd9', '#97d9e1'],
            // ['#74ebd5', '#9face6'],
            // ['#a5c0ee', '#fbc5ec'],
            // ['#ffbac3', '#2cd8d5'],
            ['#FC00FF', '#00DBDE'],
            ['#009EFD', '#2AF598'],
            ['#00FAC1', '#C500E1'],
            ['#B721FF', '#21D4FD'],
            ['#21D4FD', '#F606FF'],
          ],
        },
      },
    });
  });

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <BgImage />
          <canvas id="granim-canvas"></canvas>
          <div style={{ zIndex: 1 }}>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div
              style={{
                margin: '0 auto',
                maxWidth: 1200,
              }}
            >
              {children}
            </div>
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
