import React from 'react';
import { Link } from 'gatsby';

import IconLogo from '../images/logos/undefined_logo_stacked.svg';

const Header = ({ siteTitle }) => (
  <header
    style={{
      margin: '25px auto 100px',
      textAlign: 'center',
    }}
  >
    <Link
      to="/"
      style={{
        display: 'block',
        textDecoration: 'none',
      }}
    >
      <IconLogo className="logo" alt={siteTitle} />
    </Link>
  </header>
);

export default Header;
